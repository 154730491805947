import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgxNotificationComponent } from './ngx-notification.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule
  ],
  declarations: [NgxNotificationComponent],
  exports: [NgxNotificationComponent]
})
export class NgxNotificationModule { }
