import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NgxNotificationService {

  constructor() { }

  private subject = new Subject<any>();

  sendMessage(message: string, theme: string, position: string) {
    this.subject.next(
      {
        text: message,
        theme: theme,
        position: position
      }
    );
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
