import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxNotificationService } from './ngx-notification.service';

@Component({
  selector: 'lib-ngx-notification',
  template: `<div *ngIf='show' class='ngx-notification {{ message.position }} {{ message.theme }} show'>{{message?.text }}</div>`,
  styles: [`
  .ngx-notification.dark {
    background-color: #2d2d30;
    border-color: #272729;
    color: #c9c9c9;
  }
  .ngx-notification.light {
    background-color: #ffffff;
    border-color: #cccccc;
    color: #2d2d30;
  }
  .ngx-notification.success {
    background-color: #dff0d8;
      border-color: #d0e9c6;
      color: #3c763d;
  }
  .ngx-notification.info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
  }
  .ngx-notification.warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
  }
  .ngx-notification.danger {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
  }
  .ngx-notification.top-left {
    position: fixed;
    left: 10px;
    top: 10px;
  }
  .ngx-notification.top-right {
    position: fixed;
    right: 10px;
    top: 10px;
  }
  .ngx-notification.bottom-right {
    position: fixed;
    right: 10px;
    bottom: 10px;
  }
  .ngx-notification.bottom-left {
    position: fixed;
    left: 10px;
    bottom: 10px;
  }
  .ngx-notification.center {
    position:absolute;
      top: 50%;
      left: 50%;
      margin-top: -9em;
      margin-left: -15em;
  }
  .ngx-notification {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    -webkit-animation: fadein 1.5s, fadeout 1.5s 3.5s;
    animation: fadein 1.5s, fadeout 1.5s 3.5s;
    z-index : 1;
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {opacity: 1;}
    to {opacity: 0;}
  }

  @keyframes fadeout {
    from {opacity: 1;}
    to {opacity: 0;}
  }
  `]
})
export class NgxNotificationComponent implements OnInit, OnDestroy {

  show;
  message: any = {};
  subscription: Subscription;

  constructor(private ngxNotificationService: NgxNotificationService) {
    this.subscription = this.ngxNotificationService.getMessage()
    .subscribe(
      message => {
        this.show = true;
        this.message = message;
        console.log(this.message);
        this.autoHideMessage();
      }
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  autoHideMessage() {
    setTimeout(() => {
      this.show = false;
  }, 5000);
  }

}
